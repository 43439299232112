import React from 'react';
import { styled } from 'linaria/react';
import parse from 'html-react-parser';
import { useState } from 'react';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import PlusIcon from '../ui/icons/PlusIcon';
import MinusIcon from '../ui/icons/MinusIcon';
import { theme } from '../Theming/Theming';

const Wrapper = styled('div')`
  margin-bottom: 5rem;
  .pre-content {
    margin: 0 0 2rem 0;
  }
  svg {
    transition: all 0.3s;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
  button {
    text-align: left;
    position: relative;
    width: 100%;
    background: none;
    padding-right: 2rem;
    &:active,
    &:focus {
      border: none;
      outline: none;
    }
  }
  .section {
    padding: 1.125rem 0;
    border-bottom: 1px solid ${theme.colors.border};
    > button {
      > h3 {
        position: relative;
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 700;
        color: ${theme.colors.primary};
      }
      > svg {
        width: 14px;
        height: 14px;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    > .list {
      margin-top: 1rem;
    }
  }
  .question {
    padding: 0.75rem 0rem 0.75rem 1.25rem;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    background-color: ${theme.colors.greys[0]};
    border-radius: 5px > button {
      h4 {
        font-size: 1.125rem;
      }
      > svg {
        width: 14px;
        height: 14px;
      }
    }
    .answers {
      margin-top: 0.5rem;
      padding-top: 1rem;
      border-top: 1px solid ${theme.colors.greys[2]};
      transition: max-height 0.5s ease-in-out 0s;
      margin-right: 1.25rem;
      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: ${theme.colors.greys[6]};
      }
    }
  }
`;

const ToggleButton = ({
  data,
  icon = 'arrow',
  header = 'h3',
  className = '',
}) => {
  const Tag = header;
  const [open, setOpen] = useState(false);
  return (
    <div className={`${className}`}>
      <button
        onClick={() => {
          const handler = open ? false : true;
          setOpen(handler);
        }}
      >
        <Tag>{data.header}</Tag>
        {icon === 'arrow' ? (
          <Caret className={open ? 'open' : ''} />
        ) : open ? (
          <MinusIcon />
        ) : (
          <PlusIcon />
        )}
      </button>
      {open && (
        <>
          {className === 'question' ? (
            <div className="answers">{data.list}</div>
          ) : (
            <div className="list">
              {data.list.map((item) => (
                <ToggleButton
                  data={item}
                  className="question"
                  icon="plus"
                  header="h4"
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const FaqPage = ({ page }) => {
  //PARSE CONTENT
  const contentData = [...parse(page.content), <h3>_</h3>];
  let faqData = [];
  let preData = [];
  let section = false;
  let question = false;

  function handleObject(list, object, element = false) {
    //CLOSE PREVIOUS SECTION
    if (object) {
      list.push(object);
    }
    //CREATE NEW SECTION
    return element
      ? {
          header: element.props.children,
          list: [],
        }
      : false;
  }

  contentData.forEach(function (element, index) {
    //RETURN IF NO HTML ELEMENT
    if (!element.props) return;

    if (element.type === 'h3' || index + 1 === contentData.length) {
      question = handleObject(section.list, question);
      section = handleObject(faqData, section, element);
    } else {
      if (element.type === 'h4') {
        question = handleObject(section.list, question, element);
      } else {
        if (question.list) question.list.push(element);
        else preData.push(element);
      }
    }
  });

  return (
    <Wrapper>
      {preData.length > 0 && <div className="pre-content">{preData}</div>}
      {faqData.map((item) => (
        <ToggleButton data={item} className="section" />
      ))}
    </Wrapper>
  );
};
